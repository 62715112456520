import { css } from '@emotion/css';
import { m, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const buttonWrapperCss = css`
  display: inline-block;
  position: relative;
  margin-bottom: 12px;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked ~ div {
      border: 1px solid ${m('--palette-yellow-v100')};
      background-color: ${m('--palette-yellow-v100')};
      color: ${m('--palette-black-v200')};
    }
  }

  ${nonMobileMediaQuery} {
    flex-direction: column;
    height: 240px;
    width: 240px;
    margin-bottom: 0;
  }
`;

export const buttonContainerCss = css`
  box-sizing: border-box;
  align-items: center;
  border-radius: 26px;
  border: 1px solid ${m('--palette-black-v50')};
  background-color: ${m('--palette-plain-white')};
  color: ${m('--palette-black-v50')};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  min-height: 105px;
  padding: 12px 10px;
  position: relative;
  width: 100%;
  gap: 10px;

  &:hover {
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
  }

  span {
    text-align: center;
  }

  ${nonMobileMediaQuery} {
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px;
    width: 240px;
  }
`;

export const buttonImageCss = css`
  height: 80px;
  width: 80px;
  object-fit: cover;

  ${nonMobileMediaQuery} {
    height: 130px;
    width: 130px;
  }
`;
